import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

//import { NgxSpinnerService } from "ngx-spinner";
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;

  addBlogForm: FormGroup;
  blogSubmitted = false;

  addVideosForm: FormGroup;
  videosSubmitted = false;


  public loginStatus = false;
  private userNameUser: any = 'admin';
  private passwordUser: any = 'admin';
  public userName = '';
  public password = '';
  public spinnerDisplay = false;
  //public addStatus = false;
  public addStatus:any = 'VIEWQUOTES';
  public displaySuccessMsg = false;
  public displayPushNotiSuccessMsg = false;
  public blogsData: any;
  public videosData: any;
  public editBlogStatus = false;
  public editBlogStatusId: any;
  safeSrc: SafeResourceUrl;
  url: string = "https://youtu.be/3uwwbX8E40k";
  public swipePageTemplates : Array<any> = [
                                              {id: 'home', name: 'Home'},
                                              {id: 'profile', name: 'profile'},
                                              {id: 'gallery', name: 'Gallery'},
                                              {id: 'videos', name: 'Videos'},
                                              {id: 'contact', name: 'Contact Us'}
                                            ];
  public pushNotiForm: FormGroup;
  statusimageSrc: string;
  displayStausImageTemp: any = 'false';
  statusImages = new FormGroup({
    //name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  public sliderData: any;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public sanitizer: DomSanitizer
    //private spinner: NgxSpinnerService
  ) {
    //this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/c9F5kMUfFKk");
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
    });

    this.addBlogForm = this.formBuilder.group({
      blogText: ['', Validators.required]
    });

    this.addVideosForm = this.formBuilder.group({
      videoText: ['', Validators.required]
    });

    this.pushNotiForm = this.formBuilder.group({
            title: ['', Validators.required],
            content: ['', Validators.required],
            pushNotiPage: ['', Validators.required],
    });
    //this.getAPIData();
    this.getAllBlogs();

  }

  logout(){
    this.loginStatus = false;
    this.loginForm.reset();
  }

  get f() { return this.loginForm.controls; }

  get b() { return this.addBlogForm.controls; }

  addBlogsStatusButton(){
    this.addStatus = 'ADDQUOTES';
  }

  viewBlogsStatusButton(){
    this.addStatus = 'VIEWQUOTES';
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    if(this.loginForm.controls['username'].value == this.userNameUser && this.loginForm.controls['password'].value ==  this.passwordUser){
      this.loginStatus = true;
      this.loginForm.reset();
    } else {
      this.loginStatus = false;
    }
  }

  addBlogsDisplay(){
    this.refrestall();
    this.addStatus = 'ADDQUOTES';
  }

  viewBlogsDisplay(){
    this.refrestall();
    this.addStatus = 'VIEWQUOTES';
    this.getAllBlogs();
  }

  addVideosStatusButton(){
    this.refrestall();
    this.addStatus = 'ADDVIDEOS';
  }

  viewVideosStatusButton(){
    this.refrestall();
    this.addStatus = 'VIEWVIDEOS';
    this.getAllVideos();
  }

  addStatusImagesButton(){
    this.refrestall();
    this.addStatus = 'ADDSTATUSIMAGES';
  }

  viewStatusImagesButton(){
    this.refrestall();
    this.addStatus = 'VIEWSTATUSIMAGES';
    this.getSliderImages();
  }

  addGalleryImagesButton(){
      this.addStatus = 'ADDGALLERYIMAGES';
  }

  viewGalleryImagesButton(){
    this.refrestall();
    this.addStatus = 'VIEWGALLERYIMAGES';
    this.getGalleryImages();
  }

  viewPushNotiButton(){
    this.refrestall();
    this.addStatus = 'PUSHNOTIFICATION';
  }


  getAllBlogs(){
    this.http.get('https://sameerbadami.com/swarashree/PHPFILES/getQuotes.php').subscribe( (resp: any) =>{
      //console.log("HERERERER---"+JSON.stringify(resp));
      this.blogsData = resp;
    });
  }

  getAllVideos(){
    this.http.get('https://sameerbadami.com/swarashree/PHPFILES/getVideos.php').subscribe( (resp: any) =>{
      //console.log("HERERERER---"+JSON.stringify(resp));
      this.videosData = resp;
    });
  }

  onSubmitt(){
    if (this.addBlogForm.invalid) {
      return;
    }
    this.spinnerDisplay = true;
    const addBlogsDataTemp = {
      blogText: this.addBlogForm.controls['blogText'].value
    };
    this.blogSubmitted = true;
    let c = this.addBlogForm.controls['blogText'].value;

	if(c == null || c == '' || c == undefined){
	 console.log("Nod data");
    } else {
      console.log("Data");
      if(this.editBlogStatus == false){
        this.insrertData(addBlogsDataTemp);
      }

      if(this.editBlogStatus == true){
        //this.updateDate(addBlogsDataTemp);
      }
    }
  }

  onSubmittVideo(){
    if (this.addVideosForm.invalid) {
      return;
    }
    this.spinnerDisplay = true;
    const addBlogsDataTemp = {
      videoText: this.addVideosForm.controls['videoText'].value
    };
    this.videosSubmitted = true;
    let c = this.addVideosForm.controls['videoText'].value;

	if(c == null || c == '' || c == undefined){
	 console.log("Nod data");
    } else {
      console.log("Data");
      this.insrertVideoData(addBlogsDataTemp);
    }
  }

  insrertData(addBlogsDataTemp){
    var url = 'https://sameerbadami.com/swarashree/PHPFILES/addQuotes.php';
    var headers = new HttpHeaders();
    headers.append('content-Type', 'application/json');
    console.log("POST Fn");
    this.http.post('https://sameerbadami.com/swarashree/PHPFILES/addQuotes.php',addBlogsDataTemp, {headers: headers}).subscribe( (resp: any) =>{
      if(resp == 'Success'){
          this.displaySuccessMsg = true;
          this.videosSubmitted = false;
          this.spinnerDisplay = false;
          this.addVideosForm.reset();
          this.getAllBlogs();
      }
    });
  }

  insrertVideoData(addBlogsDataTemp){
    var url = 'https://sameerbadami.com/swarashree/PHPFILES/addVideos.php';
    var headers = new HttpHeaders();
    headers.append('content-Type', 'application/json');
    console.log("POST Fn");
    this.http.post('https://sameerbadami.com/swarashree/PHPFILES/addVideos.php',addBlogsDataTemp, {headers: headers}).subscribe( (resp: any) =>{
      if(resp == 'Success'){
          this.displaySuccessMsg = true;
          this.blogSubmitted = false;
          this.spinnerDisplay = false;
          this.addBlogForm.reset();
          this.addVideosForm.reset();
          this.addStatus = 'VIEWVIDEOS';
          this.getAllVideos();
      }
    });
  }

  /*updateDate(addBlogsDataTemp){
    var headers = new HttpHeaders();
    headers.append('content-Type', 'application/json');
    this.http.put('https://skillvelocity.in/PHPFILES/updateBlogs.php?id='+this.editBlogStatusId,addBlogsDataTemp, {headers: headers}).subscribe( (resp: any) =>{
      if(resp == 'Success'){
          this.displaySuccessMsg = true;
          this.blogSubmitted = false;
          this.spinnerDisplay = false;
          this.editBlogStatus = false;
          this.addStatus = false;
          this.editBlogStatusId = '';
          this.addBlogForm.reset();
          this.getAllBlogs();
      }

    });
  }

  editBlog(value){
    this.editBlogStatus = true;
    this.editBlogStatusId = value;
    this.http.get('https://skillvelocity.in/PHPFILES/getIndividualBlogs.php?id='+value).subscribe( (resp: any) =>{
      console.log("HERERERER---"+JSON.stringify(resp));
      this.addStatus = true;
      console.log("hdshdgshdgsd--"+resp[0].shortText);
      let a = resp;
      this.addBlogForm.patchValue({
        shortTextBlog: a[0].shortText,
        shortImageName: a[0].shortImage,
        blogText: a[0].blogText,
        blogImagename: a[0].blogImage
      });
    });
  }*/

  cancelEditBlog(){
    this.editBlogStatusId = '';
    this.editBlogStatus = false;
    this.addStatus = false;
    this.addBlogForm.reset();
    this.getAllBlogs();
  }

  deleteBlog(value){
	  //alert(value);
    this.spinnerDisplay = true;
    const body = { title: 'Angular PUT Request Example' };
    this.http.delete('https://sameerbadami.com/swarashree/PHPFILES/deleteQuotes.php?id='+value).subscribe( (resp: any) =>{
      if(resp == 'Success'){
        this.spinnerDisplay = false;
        this.getAllBlogs();
      }
    });
  }

  checkUrl(value){
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  onSubmitPushNoti(){
    this.spinnerDisplay = true;
    this.pushNotiForm.patchValue({
          title: this.pushNotiForm.controls['title'].value,
          content: this.pushNotiForm.controls['content'].value,
          pushNotiPage: this.pushNotiForm.controls['pushNotiPage'].value,
        });
        const sendPushNoti = {
                                  "to": "/topics/all",
                                  "priority":"high",
                                  "notification":{
                                      "title": this.pushNotiForm.controls['title'].value,
                                      "body": this.pushNotiForm.controls['content'].value,
                                      "click_action": "FCM_PLUGIN_ACTIVITY"
                                  },
                                  "data":{
                                            "page": this.pushNotiForm.controls['pushNotiPage'].value,
                                            "notification_foreground": "false",
                                  }
                                };
        let headers = new HttpHeaders();
        headers=headers.set('content-Type', 'application/json');
        headers=headers.set('Authorization', 'key=AAAAk6XlJW0:APA91bFWmUrPYX8W5ditLXlaW_eTf7_WTKNvHSyzV-POtMp6tTfnkD0Oo18Moy4cJBhl4gpNOO2BbDLQNBsqVAUgz2MLrr-vyfKBxvMY-rmL6NnxST0HzuSHAqE2UMJmIjdgOrL_pEdr');
        //console.log("Header in push notification--"+JSON.stringify(headers));
        this.http.post('https://fcm.googleapis.com/fcm/send',sendPushNoti, {headers: headers}).subscribe( (resp: any) =>{
              this.displayPushNotiSuccessMsg = true;
              this.pushNotiForm.reset();
              this.spinnerDisplay = false;
        });
  }

  /*getAPIData(){
    this.spinnerDisplay = true;
    var url = 'https://skillvelocity.in/PHPFILES/checkConnection.php';
    //let a = this.http.get(url);
    this.http.get(url).subscribe( (resp: any) =>{
      console.log("HERERERER---"+resp);
    });

  }*/

  onFileChange(event){
    this.displayStausImageTemp  = true;
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.statusimageSrc = reader.result as string;
        this.statusImages.patchValue({
          fileSource: reader.result
        });

      };

    }
  }

  submitStatusImages(){
    if(this.statusimageSrc == ''){
      alert("Please Select Images");
    } else {
      if( this.addStatus == 'ADDSTATUSIMAGES'){
        this.http.post('https://sameerbadami.com/swarashree//PHPFILES/uploadSliderImages.php', this.statusImages.value)
        .subscribe(res => {
          console.log(JSON.stringify(res));
          if(res == 'Success'){
            alert('Uploaded Successfully.');
            this.refrestall();
            this.statusImages.reset();
          }
        })
      }

      if( this.addStatus == 'ADDGALLERYIMAGES'){
        this.http.post('https://sameerbadami.com/swarashree//PHPFILES/uploadGalleryImages.php', this.statusImages.value)
        .subscribe(res => {
          console.log(JSON.stringify(res));
          if(res == 'Success'){
            alert('Uploaded Successfully.');
            this.refrestall();
            this.statusImages.reset();
          }
        })
      }

    }
  }

  getSliderImages(){
    this.http.get('https://sameerbadami.com/swarashree//PHPFILES/getSliderImages.php').subscribe( (resp: any) =>{
      resp.forEach(function (value) {
            value.sliderImage = 'https://sameerbadami.com/swarashree/PHPFiles/uploadSliderImages/'+value.sliderImage;
        });
      this.sliderData = resp;
    });
  }

  getGalleryImages(){
    this.http.get('https://sameerbadami.com/swarashree//PHPFILES/getGalleryImages.php').subscribe( (resp: any) =>{
      resp.forEach(function (value) {
            value.imageName = 'https://sameerbadami.com/swarashree/PHPFiles/uploadGalleryImages/'+value.imageName;
        });
      this.sliderData = resp;
    });
  }

  enableSliderImage(id){
    this.http.get('https://sameerbadami.com/swarashree/PHPFiles/enableSliderImages.php?id='+id).subscribe( (resp: any) =>{
      if(resp == 'Success'){
        this.getSliderImages();
      }
    });
  }

  disableSliderImage(id){
    this.http.get('https://sameerbadami.com/swarashree/PHPFiles/disableSliderImages.php?id='+id).subscribe( (resp: any) =>{
      if(resp == 'Success'){
        this.getSliderImages();
      }
    });
  }


  refrestall(){
    this.statusimageSrc = '';
    this.displaySuccessMsg = false;
  }








}
