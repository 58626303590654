<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Swarashree Music Academy</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarColor03">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <a class="nav-link active" href="#">Admin
            <span class="visually-hidden">(current)</span>
          </a>
        </li>
      </ul>
      <form class="d-flex">
        <button *ngIf="loginStatus == true" type="button" class="btn btn-primary my-2 my-sm-0" (click)="logout()">LogOut</button>
      </form>
    </div>
  </div>
</nav>

<!--Content-->
<div class="container">
    <!--Login Form-->
    <div *ngIf="loginStatus == false" class="row" style="padding-top:50px;">
      <div class="col-md-3"></div>
      <div class="col-md-6" style="border: 4px solid #ccc">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                  <label for="username">Username</label>
                  <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required">Username is required</div>
                  </div>
              </div>
              <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
              </div>
              <div class="form-group">
                  <button [disabled]="loading" class="btn btn-primary">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                      Login
                  </button>
              </div>
           </form>
      </div>

      <div class="col-md-3"></div>
    </div>
    <!--Login Form-->

    <!--Blogs Data -->
    <div *ngIf="loginStatus == true" class="row" style="padding-top:10px;">
      <div class="col-md-14">
        <div class="alert alert-dismissible alert-warning">
          <h4 class="alert-heading">Welcome,</h4>
          <p class="mb-0">Now you are in Admin Panel</p>
        </div>
        <!--Tabs starts here-->
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <!--<a class="nav-link" data-bs-toggle="tab" href="#home">Add Blogs</a>-->
            <a class="nav-link" [ngClass]="{'active': addStatus==='ADDQUOTES'}"  data-bs-toggle="tab" style="cursor:pointer" (click)="addBlogsStatusButton()">Add Quotes</a>
          </li>
          <li class="nav-item">
            <!--<a class="nav-link active" data-bs-toggle="tab" href="#profile">View Blogs</a>-->
            <a class="nav-link" [ngClass]="{'active': addStatus==='VIEWQUOTES'}" data-bs-toggle="tab" style="cursor:pointer" (click)="viewBlogsStatusButton()">View Quotes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': addStatus==='ADDVIDEOS'}" data-bs-toggle="tab" style="cursor:pointer" (click)="addVideosStatusButton()">Add Videos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': addStatus==='VIEWVIDEOS'}" data-bs-toggle="tab" style="cursor:pointer" (click)="viewVideosStatusButton()">View Videos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': addStatus==='ADDSTATUSIMAGES'}" data-bs-toggle="tab" style="cursor:pointer" (click)="addStatusImagesButton()">Add Status Images</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': addStatus==='VIEWSTATUSIMAGES'}" data-bs-toggle="tab" style="cursor:pointer" (click)="viewStatusImagesButton()">View Status Images</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': addStatus==='ADDGALLERYIMAGES'}" data-bs-toggle="tab" style="cursor:pointer" (click)="addGalleryImagesButton()">Add Gallery Images</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': addStatus==='VIEWGALLERYIMAGES'}" data-bs-toggle="tab" style="cursor:pointer" (click)="viewGalleryImagesButton()">View Gallery Images</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': addStatus==='PUSHNOTIFICATION'}" data-bs-toggle="tab" style="cursor:pointer" (click)="viewPushNotiButton()">Send Push Notification</a>
          </li>
        </ul>
		<!-- Adding Quote-->
        <div id="myTabContent" class="tab-content">
          <!--Add and View quotes-->
          <div  class="tab-pane fade show active" *ngIf="addStatus == 'ADDQUOTES'" id="home">
            <div *ngIf="displaySuccessMsg == true" class="row">
              <div class="col-md-3"></div>
              <div style="padding-top:20px;" class="col-md-6">
                <div class="alert alert-dismissible alert-success">
                  <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                   New quote added successfully.
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div  class="row">
              <div class="col-md-1">{{spinner}}</div>
              <div *ngIf="spinnerDisplay == false" class="col-md-10">
                <br>
                <form [formGroup]="addBlogForm" (ngSubmit)="onSubmitt()">
                  <div class="form-group">
                      <label for="username">Enter Quote Text</label>
                      <textarea formControlName="blogText" class="form-control" id="exampleTextarea" rows="5" [ngClass]="{ 'is-invalid': blogSubmitted && b.blogText.errors }"></textarea>
                      <div *ngIf="blogSubmitted && b.blogText.errors" class="invalid-feedback">
                          <div *ngIf="b.blogText.errors.required">Quote Text is required</div>
                      </div>
                  </div>
                  <div class="form-group">
                      <button [disabled]="loading" class="btn btn-primary">
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                          Add Quote
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button *ngIf="editBlogStatus == true" class="btn btn-danger" (click)="cancelEditBlog()">
                          Cancel
                      </button>
                  </div>
                </form>
              </div>
              <div *ngIf="spinnerDisplay == true" class="col-md-10">
                <div style="margin-top:150px" class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
          <div  class="tab-pane fade show active" *ngIf="addStatus == 'VIEWQUOTES'" id="profile">
            <table class="table table-hover">
              <thead>
                <tr>
                  <!--<th scope="col">Sl No</th>-->
                  <th scope="col">Quote</th>
                  <!--<th>Edit</th>-->
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor = "let display of blogsData; let i = index">
				  <!--<th scope="row" style="width:35px">{{i+1}} </th>-->
				  <td>{{display.quotes}}</td>
				  <td style="width:50px"><button type="button" class="btn btn-outline-danger" (click)="deleteBlog(display.slNo)" >Delete</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!---->
          <!--Add and View Videos-->
          <div  class="tab-pane fade show active" *ngIf="addStatus == 'ADDVIDEOS'" id="home">
            <div *ngIf="displaySuccessMsg == true" class="row">
              <div class="col-md-3"></div>
              <div style="padding-top:20px;" class="col-md-6">
                <div class="alert alert-dismissible alert-success">
                  <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                   New video added successfully.
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div  class="row">
              <div class="col-md-1">{{spinner}}</div>
              <div *ngIf="spinnerDisplay == false" class="col-md-10">
                <br>
                <form [formGroup]="addVideosForm" (ngSubmit)="onSubmittVideo()">
                  <div class="form-group">
                      <label for="username">Enter Youtube Videos URL</label>
                      <textarea formControlName="videoText" class="form-control" id="exampleTextarea" rows="5" [ngClass]="{ 'is-invalid': videosSubmitted && b.videoText.errors }"></textarea>
                      <div *ngIf="videosSubmitted && b.videoText.errors" class="invalid-feedback">
                          <div *ngIf="b.videoText.errors.required">Quote Text is required</div>
                      </div>
                  </div>
                  <div class="form-group">
                      <button [disabled]="loading" class="btn btn-primary">
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                          Add Video
                      </button>
                      &nbsp;&nbsp;&nbsp;
                  </div>
                </form>
              </div>
              <div *ngIf="spinnerDisplay == true" class="col-md-10">
                <div style="margin-top:150px" class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
          <div  class="tab-pane fade show active" *ngIf="addStatus == 'VIEWVIDEOS'" id="profile">
            <table class="table table-hover">
              <thead>
                <tr>
                  <!--<th scope="col">Sl No</th>-->
                  <th scope="col">Videos</th>
                  <!--<th>Edit</th>-->
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor = "let display of videosData; let i = index">
                  <td>
                      <iframe [class.thumbnail]="thumbnail" [src]="checkUrl(display.videoUrl)" width="450" height="300" frameborder="0" webkitallowfullscreen mozallowfullscreen
                        allowfullscreen></iframe>
                  </td>
        				  <td style="width:50px"><button type="button" class="btn btn-outline-danger" (click)="deleteBlog(display.slNo)" >Delete</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Add and View Status Images-->
          <div  class="tab-pane fade show active" *ngIf="addStatus == 'ADDSTATUSIMAGES'" id="home">
            <div *ngIf="displaySuccessMsg == true" class="row">
              <div class="col-md-3"></div>
              <div style="padding-top:20px;" class="col-md-6">
                <div class="alert alert-dismissible alert-success">
                  <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                   New video added successfully.
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div  class="row">
              <div class="col-md-1">{{spinner}}</div>
              <div *ngIf="spinnerDisplay == false" class="col-md-10">
                <br>
                <form [formGroup]="statusImages" (ngSubmit)="submitStatusImages()">
                  <div class="form-group">
                      <label for="file">File</label>
                      <input
                          formControlName="file"
                          id="file"
                          type="file"
                          class="form-control"
                          accept="image/*"
                          (change)="onFileChange($event)">
                      <!--<div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                          <div *ngIf="f.file.errors.required">File is required.</div>
                      </div>-->
                  </div>
                  <img  [src]="statusimageSrc" *ngIf="statusimageSrc" style="height: 300px; width:500px;text-align:center;border:2px solid #0d6efd">
                  <br><br>
                  <button class="btn btn-primary" [disabled]="statusimageSrc ==''" type="submit">Upload Image</button>
                </form>
              </div>
              <div *ngIf="spinnerDisplay == true" class="col-md-10">
                <div style="margin-top:150px" class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>

          <div  class="tab-pane fade show active" *ngIf="addStatus == 'VIEWSTATUSIMAGES'" id="home">
            <table class="table table-hover">
              <thead>
                <tr >
                  <th scope="col">Sl No</th>
                  <th scope="col">Slider Image</th>
                  <th scope="col">Status</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor = "let display of sliderData; let i = index">
                  <td scope="row">{{sliderData.length - i}}</td>
                  <td scope="row">
                    <img style="border:3px solid #0d6efd; width:450px; height: 250px" [src]='display.sliderImage' class="rounded float-center" alt="...">
                  </td>
                  <td scope="row">
                    <button *ngIf="display.displayStatus == 'false'" type="button" class="btn btn btn-danger" (click)="enableSliderImage(display.slNo)">Enable</button>
                    <button *ngIf="display.displayStatus == 'true'" type="button" class="btn btn btn-success" (click)="disableSliderImage(display.slNo)">Disable</button>
                  </td>
                  <td scope="row"><button type="button" class="btn btn-outline-danger">Delete</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Add and View Gallery Images-->
          <div  class="tab-pane fade show active" *ngIf="addStatus == 'ADDGALLERYIMAGES'" id="home">
            <div *ngIf="displaySuccessMsg == true" class="row">
              <div class="col-md-3"></div>
              <div style="padding-top:20px;" class="col-md-6">
                <div class="alert alert-dismissible alert-success">
                  <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                   New video added successfully.
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div  class="row">
              <div class="col-md-1">{{spinner}}</div>
              <div *ngIf="spinnerDisplay == false" class="col-md-10">
                <br>
                <form [formGroup]="statusImages" (ngSubmit)="submitStatusImages()">
                  <div class="form-group">
                      <label for="file">File</label>
                      <input
                          formControlName="file"
                          id="file"
                          type="file"
                          class="form-control"
                          accept="image/*"
                          (change)="onFileChange($event)">
                  </div>
                  <img  [src]="statusimageSrc" *ngIf="statusimageSrc" style="height: 300px; width:500px;text-align:center;border:2px solid #0d6efd">
                  <br><br>
                  <button class="btn btn-primary" [disabled]="statusimageSrc ==''" type="submit">Upload Image</button>
                </form>
              </div>
              <div *ngIf="spinnerDisplay == true" class="col-md-10">
                <div style="margin-top:150px" class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>

          <div  class="tab-pane fade show active" *ngIf="addStatus == 'VIEWGALLERYIMAGES'" id="home">
            <table class="table table-hover">
              <thead>
                <tr >
                  <th scope="col">Sl No</th>
                  <th scope="col">Gallery Image</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor = "let display of sliderData; let i = index">
                  <td scope="row">{{sliderData.length - i}}</td>
                  <td scope="row">
                    <img style="border:3px solid #0d6efd; width:450px; height: 250px" [src]='display.imageName' class="rounded float-center" alt="...">
                  </td>
                  <td scope="row"><button type="button" class="btn btn-outline-danger">Delete</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--Send PUSHNOTIFICATION-->
          <div  class="tab-pane fade show active" *ngIf="addStatus == 'PUSHNOTIFICATION'" id="home">
            <div *ngIf="displayPushNotiSuccessMsg == true" class="row">
              <div class="col-md-3"></div>
              <div style="padding-top:20px;" class="col-md-6">
                <div class="alert alert-dismissible alert-success">
                  <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                   Push Notification Sent successfully.
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div  class="row">
              <div class="col-md-1">{{spinner}}</div>
              <div *ngIf="spinnerDisplay == false" class="col-md-10">
                <br>
                <form [formGroup]="pushNotiForm" (ngSubmit)="onSubmitPushNoti()">
                  <fieldset>
                    <div class="form-group">
                      <label >Push Notification Title <span style="color:red; font-size:25px">*</span></label>
                      <input type="text" formControlName="title"  class="form-control" [ngClass]="{ 'is-invalid': submitted &amp;&amp; pushNotiForm.controls.title.errors }">
                      <div *ngIf="submitted &amp;&amp; pushNotiForm.controls.title.errors" class="text-danger">
                        <div *ngIf="pushNotiForm.controls.title.errors.required">Push Notification Title Required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label >Push Notification Content <span style="color:red; font-size:25px">*</span></label>
                      <input type="text" formControlName="content"  class="form-control" [ngClass]="{ 'is-invalid': submitted &amp;&amp; pushNotiForm.controls.content.errors }">
                      <div *ngIf="submitted &amp;&amp; pushNotiForm.controls.content.errors" class="text-danger">
                        <div *ngIf="pushNotiForm.controls.content.errors.required">Push Notification Content Required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Select Page</label>
                        <select class="form-control"  formControlName="pushNotiPage" [ngClass]="{ 'is-invalid': submitted &amp;&amp; pushNotiForm.controls.pushNotiPage.errors }">
                          <option *ngFor="let option of swipePageTemplates" [value]="option.id">{{option.name}}</option>
                        </select>
                        <div *ngIf="submitted &amp;&amp; pushNotiForm.controls.pushNotiPage.errors" class="text-danger">
                          <div *ngIf="pushNotiForm.controls.pushNotiPage.errors.required">Select Page</div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-lg btn-block">SEND PUSH NOTIFICATION TO ALL USERS</button>
                  </fieldset>
                </form>
              </div>
              <div *ngIf="spinnerDisplay == true" class="col-md-10">
                <div style="margin-top:150px" class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
        </div>
        <!-- Tabs ends here-->
      </div>
    </div>
    <!--Blogs Data-->
</div>
